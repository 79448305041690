import React from 'react';
import Dropdown from 'shared/components/Dropdown/Dropdown';

import styles from './TableRowsNumber.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  rowsPerPage: number;
  availableNumbersOfRows: number[];
  onNumberOfRowsSelected: (rowNumber: number) => void;
}

function TableRowsNumber({ rowsPerPage, onNumberOfRowsSelected, availableNumbersOfRows }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.showLabel}>{t('tables.pagination.show')}:</div>

      <div className={styles.rowNumber}>
        <Dropdown
          className={styles.numberSelect}
          value={rowsPerPage}
          options={availableNumbersOfRows.map((n) => ({ value: n, label: `${n}` }))}
          onChange={(value) => onNumberOfRowsSelected(+value)}
          isSearchable={false}
        />
      </div>
    </div>
  );
}

export default TableRowsNumber;
