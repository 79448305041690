import React from 'react';
import styles from './TablePagination.module.scss';
import classNames from 'classnames';
import TableRowsNumber from './components/TableRowsNumber/TableRowsNumber';
import { usePagination } from './helpers';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AngleIcon } from 'static/icons/angle-right.svg';

const MAX_VISIBLE_PAGES = 5;

interface Props {
    pageNumber: number;
    rowsPerPage: number;
    totalRows: number;
    availableNumbersOfRows: number[];
    onPageSelected: (pageNumber: number) => void;
    onNumberOfRowsSelected: (rowNumber: number) => void;
    breakLabel: string;
}

function TablePagination({
    pageNumber,
    rowsPerPage,
    totalRows,
    onPageSelected,
    availableNumbersOfRows,
    onNumberOfRowsSelected,
    breakLabel
}: Props): React.ReactElement {
    const { t } = useTranslation();

    const totalPages = rowsPerPage > 0 ? Math.ceil(totalRows / rowsPerPage) : 1;
    const paginationRange = usePagination(totalPages, pageNumber, breakLabel, MAX_VISIBLE_PAGES);

    return (
        <div className={styles.root}>
            {totalRows > rowsPerPage && (
                <div className={styles.pagination}>
                    <button
                        onClick={() => onPageSelected(0)}
                        disabled={pageNumber === 0}
                        className={classNames(styles.paginationButton)}
                    >
                        {t('tables.pagination.first')}
                    </button>

                    <button
                        onClick={() => onPageSelected(pageNumber - 1)}
                        disabled={pageNumber === 0}
                        className={styles.paginationButton}
                    >
                        <AngleIcon className={styles.rightIcon} />
                    </button>

                    <div className={styles.pageNumberButtons}>
                        {paginationRange.map((item, idx) => {
                            return (
                                item === breakLabel ? (
                                    <span key={idx}>{breakLabel}</span>
                                ) : (
                                    <button
                                        key={idx}
                                        className={classNames(styles.paginationButton, styles.numberButton, {
                                            [styles.active]: pageNumber === item,
                                        })}
                                        onClick={() => onPageSelected(Number(item))}
                                    >
                                        {+item + 1}
                                    </button>
                                )
                            )
                        }
                        )}
                    </div>
                    <button
                        onClick={() => onPageSelected(pageNumber + 1)}
                        disabled={pageNumber === totalPages - 1 || totalPages === 0}
                        className={styles.paginationButton}
                    >
                        <AngleIcon />
                    </button>

                    <button
                        onClick={() => onPageSelected(totalPages - 1)}
                        disabled={pageNumber === totalPages - 1 || totalPages === 0}
                        className={styles.paginationButton}
                    >
                        {t('tables.pagination.last')}
                    </button>
                </div>
            )}

            <TableRowsNumber
                rowsPerPage={rowsPerPage}
                onNumberOfRowsSelected={onNumberOfRowsSelected}
                availableNumbersOfRows={availableNumbersOfRows}
            />
        </div>
    );
}

export default TablePagination;
