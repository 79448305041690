import React, { useRef, UIEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

import TableBody from './components/TableBody/TableBody';
import TableHeader from './components/TableHeader/TableHeader';
import TablePagination from './components/TablePagination/TablePagination';
import { HeaderInfo, OnFilter, OnSort, SortInfo, TableFilters } from './types';
import { AVAILABLE_NUMBERS_OF_ROWS } from './constants';

import styles from './Table.module.scss';

interface Props {
  rows: React.ReactNode[][];
  headers: HeaderInfo[];
  sort?: SortInfo;
  filters?: TableFilters;
  pageNumber?: number;
  rowsPerPage?: number;
  totalRows?: number;
  availableNumbersOfRows?: number[];
  onSort?: OnSort;
  onFilter?: OnFilter;
  onPageSelected?: (pageNumber: number) => void;
  onNumberOfRowsSelected?: (rowNumber: number) => void;
}

export default function Table({
  rows,
  rowsPerPage,
  availableNumbersOfRows = AVAILABLE_NUMBERS_OF_ROWS,
  headers,
  sort,
  filters = {},
  totalRows = 0,
  pageNumber = 0,
  onSort = noop,
  onFilter = noop,
  onPageSelected = noop,
  onNumberOfRowsSelected = noop,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const headerContainerRef = useRef<HTMLDivElement>(null);
  const bodyContainerRef = useRef<HTMLDivElement>(null);

  const handleBodyScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollTarget = e.target as HTMLDivElement;
    headerContainerRef.current!.scrollLeft = scrollTarget.scrollLeft;
  };

  const handleHeaderScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollTarget = e.target as HTMLDivElement;
    bodyContainerRef.current!.scrollLeft = scrollTarget.scrollLeft;
  };

  useEffect(() => {
    onPageSelected(pageNumber);
  }, [totalRows]);

  return (
    <div className={classNames(styles.root, 'table-root')}>
      <div ref={headerContainerRef} onScroll={throttle(handleHeaderScroll, 5)}
        className={styles.tableHeaderHolder}>
        <table cellSpacing={0} className={styles.table}>
          <TableHeader
            onFilter={onFilter}
            sort={sort}
            headers={headers}
            onSort={onSort}
            filters={filters}
          />
        </table>
      </div>
      <div ref={bodyContainerRef} onScroll={throttle(handleBodyScroll, 5)} className={styles.tableHolder}>
        {rows?.length ? (
          <table cellSpacing={0} className={styles.table}>
            <TableHeader
              onFilter={onFilter}
              sort={sort}
              headers={headers}
              onSort={onSort}
              filters={filters}
              className={styles.hidden}
            />
            <TableBody rows={rows} />
          </table>
        ) : (
          <div className={styles.noEntriesBlock}>
            <div className={styles.stickyLabel}>{t('tables.no_entries')}</div>
          </div>
        )}
      </div>
      {!!totalRows && rowsPerPage !== undefined && pageNumber !== undefined && (
        <TablePagination
          onPageSelected={onPageSelected}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          onNumberOfRowsSelected={onNumberOfRowsSelected}
          availableNumbersOfRows={availableNumbersOfRows}
          pageNumber={pageNumber}
          breakLabel='...'
        />
      )}
    </div>
  );
}
