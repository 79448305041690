export const getPaging = ({
  rowsPerPage,
  totalRows,
  maxVisiblePages,
  pageNumber,
}: {
  rowsPerPage: number;
  totalRows: number;
  maxVisiblePages: number;
  pageNumber: number;
}): { startPage: number; endPage: number; totalPages: number } => {
  const totalPages = rowsPerPage > 0 ? Math.ceil(totalRows / rowsPerPage) : 1;

  let startPage = 0;
  let endPage = totalPages;

  if (totalPages <= maxVisiblePages) {
    startPage = 0;
    endPage = totalPages;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxVisiblePages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxVisiblePages / 2) - 1;

    if (pageNumber <= maxPagesBeforeCurrentPage) {
      startPage = 0;
      endPage = maxVisiblePages;
    } else if (pageNumber + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxVisiblePages;
      endPage = totalPages;
    } else {
      startPage = pageNumber - maxPagesBeforeCurrentPage;
      endPage = pageNumber + maxPagesAfterCurrentPage + 1;
    }
  }

  return { startPage, endPage, totalPages };
};


export const usePagination = (
  totalPages: number,
  pageNumber: number,
  breakLabel: string,
  maxVisiblePages: number
) => {
  const siblingCount = 1;

  const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, idx) => idx + start);
  };

  if (totalPages <= maxVisiblePages) {
    return range(0, totalPages -1);
  }

  const showLeftDots = pageNumber > siblingCount + 2;
  const showRightDots = pageNumber < totalPages - siblingCount - 1;

  if (!showLeftDots && showRightDots) {
    return [...range(0, 3 + siblingCount), breakLabel, totalPages - 1];
  }

  if (showLeftDots && !showRightDots) {
    return [0, breakLabel, ...range(totalPages - 2 - siblingCount, totalPages - 1)];
  }

  if (showLeftDots && showRightDots) {
    return [
      0,
      breakLabel,
      ...range(pageNumber - siblingCount, pageNumber + siblingCount),
      breakLabel,
      totalPages - 1,
    ];
  }

  return [];
};