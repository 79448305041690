import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUser, patchUserAndUserImage, postRegisterUser, resetUserPassword } from 'api/user';
import { User } from 'interfaces/user';
import { RegisterUserEntity } from './types';
import { toast } from 'react-toastify';
import { userInfoDataService } from '../../../shared/services/user-info-service';
import cookie from 'js-cookie';
import i18n from 'i18n';


export const getUser = createAsyncThunk('user/getUser', async () => {
    const { data } = await fetchUser();

    const userInformation = data?.user?.information;
    const currentLanguage = cookie.get('i18next');

    if (!currentLanguage || currentLanguage !== (userInformation.preferred_language || 'en')) {
        cookie.set('i18next', userInformation.preferred_language || 'en');
        window.location.reload(); // Reload to apply language changes
    }
    return data;
});

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async ({ user, image }: { user: Partial<User>; image?: File | null }) => {
        try {
            const { data } = await patchUserAndUserImage(user, image) || {};
            toast.success(i18n.t('toast_messages.profile_info'),{
                position: toast.POSITION.TOP_RIGHT
            });
            userInfoDataService.setData(data.details.profile_image)
            return data;
        } catch (e) {
            toast.error(i18n.t('toast_messages.error'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
);

export const registerUser = createAsyncThunk(
    'user/registerUser',
    async ({ registerUserEntity }: { registerUserEntity: RegisterUserEntity }) => {
        await postRegisterUser(registerUserEntity);
    }
);


export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (user: User) => {
        try {
            const data = await resetUserPassword(user);
            toast.success(i18n.t('toast_messages.reset_password'), {
                position: toast.POSITION.TOP_RIGHT
            });
            return data;
        } catch (e) {
            toast.error(i18n.t('toast_messages.error'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
);