import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Security.module.scss';
import { User } from 'interfaces/user';
import { getUser, resetPassword } from 'redux/features/user';
import { RootState } from 'redux/store';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import Button from 'shared/components/Button/Button';
import { Form, Formik } from 'formik';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import NavLinks from 'components/NavLinks/NavLinks';
import { setAccountPath } from 'redux/features/accountPath';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/components/Modal/Modal';

const SecurityComponent: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = useSelector<RootState, User | null>((state) => state.user.user);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setAccountPath({ path: '/', label: 'navigation.title.my_account' }))
        }
    }, [])

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setIsSubmitting(false);
    }, [user]);

    const handleReset = () => {
        user && dispatch(resetPassword(user));
        setModal(false);
    }

    return (
        <div className={styles.root}>
            <PageBlock>
                <NavLinks />
                {user && (
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={() => setModal(true)}
                    >
                        {({ dirty, values }) => (
                            <Form className={styles.form}>
                                {/* <PasswordInfo values={values} /> */}

                                <div className={styles.resetDescription}>
                                    <p>{t('security.reset_description.details_1')}</p>
                                    <p>{t('security.reset_description.details_2')}</p>
                                </div>
                                <div className={styles.formActions}>
                                    <Button
                                        type="submit"
                                        action
                                        large
                                        className={styles.submitButton}
                                        disabled={isSubmitting}
                                    >
                                        {/* {t('security.update_password')} */}
                                        {t('security.reset_password')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

                {modal && (
                    <Modal
                        onClose={() => setModal(false)}
                        title={t('security.reset_password')}
                    >
                        <div className={styles.description}>{t('modals.reset_password.title')}</div>

                        <div className={styles.controlsContainer}>
                            <Button danger2 onClick={() => setModal(false)}>{t('modals.reset_password.buttons.cancel')}</Button>
                            <Button action onClick={handleReset}>{t('modals.reset_password.buttons.reset')}</Button>
                        </div>
                    </Modal>
                )}

                {(isSubmitting || !user) && <LoadingOverlay />}
            </PageBlock>
        </div>
    );
};

export const Security = memo(SecurityComponent);
