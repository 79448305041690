import React from 'react';
import styles from './Modal.module.scss';
import { ReactComponent as CloseIcon } from 'static/icons/cross-small.svg';

interface Props {
  children?: React.ReactNode;
  title: string;
  onClose: () => void;
}

export function Modal({ title, onClose, children }: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <div
        className={styles.overlay}
        onClick={onClose}
      />

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>
          <button onClick={onClose}>
            <CloseIcon className={styles.icon} />
          </button>
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
}
