import React, { FC, memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from 'shared/components/Button/Button';
import Input from 'shared/components/Input/Input';
import styles from './InviteUser.module.scss';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
    onEmailSubmit: (email: string, firstName: string, lastName: string) => void;
    onValueChange: () => void;
    errorMessage?: string | null;
    setIsInviteUiShown: (isInviteUiShown: boolean) => void;
}

const InviteUserComponent: FC<Props> = ({ onEmailSubmit, errorMessage, onValueChange, setIsInviteUiShown }) => {
    const { t } = useTranslation();

    const [inviteEmail, setInviteEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const onFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            onEmailSubmit(inviteEmail, firstName, lastName);
            setIsInviteUiShown(false)
        },
        [onEmailSubmit, inviteEmail, firstName, lastName]
    );

    const disabledForm = () => {
        return inviteEmail.length === 0 || firstName.length === 0 || lastName.length === 0;
    }

    return (
        <Formik
            initialValues={{ firstName, lastName, inviteEmail }}
            onSubmit={onFormSubmit}
        >
            <form onSubmit={onFormSubmit}
                className={styles.inviteFormContainer}>

                <div className={styles.nameBox}>
                    <div className={classNames(styles.inputBox, styles.required)}>
                        <Input
                            type="text"
                            name="invite_firstname"
                            required
                            placeholder={t('modals.invite_user.placeholders.first_name')}
                            value={firstName}
                            onChange={(e) => {
                                onValueChange();
                                setFirstName(e.target.value);
                            }}
                            error={errorMessage}
                        />
                    </div>

                    <div className={classNames(styles.inputBox, styles.required)}>
                        <Input
                            type="text"
                            name="invite_lastname"
                            required
                            placeholder={t('modals.invite_user.placeholders.last_name')}
                            value={lastName}
                            onChange={(e) => {
                                onValueChange();
                                setLastName(e.target.value);
                            }}
                            error={errorMessage}
                        />
                    </div>
                </div>

                <div className={classNames(styles.inputBox, styles.required)}>
                    <Input
                        type="email"
                        name="invite_email"
                        required
                        placeholder={t('modals.invite_user.placeholders.user_email')}
                        value={inviteEmail}
                        onChange={(e) => {
                            onValueChange();
                            setInviteEmail(e.target.value);
                        }}
                        error={errorMessage}
                    />
                </div>

                <div className={styles.inviteFormControlsContainer}>
                    <Button danger2 onClick={() => setIsInviteUiShown(false)}>{t('modals.invite_user.buttons.cancel')}</Button>
                    <Button action disabled={disabledForm()} type="submit">{t('modals.invite_user.buttons.invite')}</Button>
                </div>
            </form>
        </Formik>
    );
};

export const InviteUser = memo(InviteUserComponent);
