import { NavigationItem } from 'interfaces/uiConstants';
import { NavigationCard } from 'interfaces/uiConstants';

import personalInfo from 'static/icons/myAccount/personal-info.svg'
import loginSecurity from 'static/icons/myAccount/login-security.svg'
import companyInfo from 'static/icons/myAccount/company-info.svg'
import companySettings from 'static/icons/myAccount/settings.svg'
import teamMembers from 'static/icons/myAccount/team-members.svg'
import termsContracts from 'static/icons/myAccount/terms-contracts.svg'

export const navigationList: NavigationItem[] = [
  {
    name: 'Personal Information',
    paths: ['/profile'],
  },
  {
    name: 'Company Information',
    paths: ['/company'],
  },
  {
    name: 'Team Members',
    paths: ['/team'],
  },
  {
    name: 'Company Settings',
    paths: ['/settings'],
  },
  {
    name: 'Terms and Contracts',
    paths: ['/terms'],
  },
];

export const navigationCards: NavigationCard[] = [
  {
    icon: personalInfo,
    title: 'navigation.title.personal_information',
    description: 'navigation.description.personal_information',
    path: '/profile'
  },
  {
    icon: loginSecurity,
    title: 'navigation.title.login_security',
    description: 'navigation.description.login_security',
    path: '/security'
  },
  // {
  //   icon: billing,
  //   title: 'navigation.title.billing',
  //   description: 'navigation.description.billing',
  //   path: '/billing',
  //   disabled: true
  // },
  {
    icon: companyInfo,
    title: 'navigation.title.company_information',
    description: 'navigation.description.company_information',
    path: '/company'
  },
  {
    icon: companySettings,
    title: 'navigation.title.settings',
    description: 'navigation.description.settings',
    path: '/settings'
  },
  // {
  //   icon: notifications,
  //   title: 'navigation.title.notifications',
  //   description: 'navigation.description.notifications',
  //   path: '/notifications',
  //   disabled: true
  // },
  // {
  //   icon: privacySharing,
  //   title: 'navigation.title.privacy_sharing',
  //   description: 'navigation.description.privacy_sharing',
  //   path: '/privacy',
  //   disabled: true
  // },
  {
    icon: teamMembers,
    title: 'navigation.title.team_members',
    description: 'navigation.description.team_members',
    path: '/team'
  },
  {
    icon: termsContracts,
    title: 'navigation.title.terms_contracts',
    description: 'navigation.description.terms_contracts',
    path: '/terms'
  },
  // {
  //   icon: connections,
  //   title: 'navigation.title.my_connections',
  //   description: 'navigation.description.my_connections',
  //   path: '/connections',
  //   disabled: true
  // },
];
