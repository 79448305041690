import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { FormikDropdown } from 'shared/components/FormikDropdown';
import { ReactComponent as PrimaryContactIcon } from 'static/icons/contact-icon.svg';

import styles from './PrimaryContact.module.scss';
import { titleDropdownVaules } from 'pages/constants';
import { useTranslation } from 'react-i18next';

interface Props {
    values: { [key: string]: any }
}

const PrimaryContactComponent: FC<Props> = ({ values }) => {
    const { t } = useTranslation();
    const { contacts: { primary_ambassador: { title, first_name, last_name, email, biz_phone } } } = values;

    return (
        <>
            <div className={styles.mainTitle}>
                <PrimaryContactIcon className={styles.titleIcon} />
                <p className={styles.titleText}>{t('company_information.primary_contact.section')}</p>
            </div>

            <InputField fieldName={t('company_information.primary_contact.title')} value={title} disabled>
                <FormikDropdown
                    isDisabled={true}
                    name="contacts.primary_ambassador.title"
                    options={titleDropdownVaules}
                />
            </InputField>

            <div className={styles.pairedFieldsContainer}>
                <InputField fieldName={t('company_information.primary_contact.first_name')} value={first_name} disabled>
                    <Input disabled name="contacts.primary_ambassador.first_name" formikField />
                </InputField>

                <InputField fieldName={t('company_information.primary_contact.last_name')} value={last_name} disabled>
                    <Input disabled name="contacts.primary_ambassador.last_name" formikField />
                </InputField>
            </div>

            <InputField fieldName={t('company_information.primary_contact.email_address')} value={email} disabled>
                <Input disabled name="contacts.primary_ambassador.email" type="email" formikField />
            </InputField>

            <InputField fieldName={t('company_information.primary_contact.business_phone_number')} value={biz_phone} disabled>
                <Input
                    disabled
                    type="number"
                    onKeyDown={(e) => ['e', 'E'].includes(e.key) && e.preventDefault()}
                    name="contacts.primary_ambassador.biz_phone"
                    formikField
                />
            </InputField>
        </>
    );
};

export const PrimaryContact = memo(PrimaryContactComponent);
